#commandbar-wrapper *,
#commandbar-wrapper *:before,
#commandbar-wrapper *:after {
  box-sizing: border-box;
}

.commandbar-modal > .rc-dialog > .rc-dialog-content {
  background-color: transparent;
  /* Enables 3d animations within the bar */
  perspective: 100px;
}

.commandbar-tooltip {
  z-index: 2147483647 !important; /*make the tooltip the highest possible z index*/
}

/*** Hack: Hide this because it's less flexible (classnames can't be changes)
    Instead we only show its children, by translating its children ***/
.commandbar-modal > .rc-dialog > .rc-dialog-content > .rc-dialog-header {
  max-height: 0px !important;
  padding: 0px !important;
}

.commandbar-modal > .rc-dialog {
  left: 0px;
  transition: left 0.3s;
}

.commandbar-modal > .rc-dialog > .rc-dialog-content > .rc-dialog-body {
  position: relative !important;
}

/* TestMode inline CommandBar */
.commandbar-modal-inline.rc-dialog-wrap {
  position: relative;
  height: 100%;
}

#commandbar-container > div {
  height: 100%;
}

#commandbar-home > .rc-dialog-root {
  height: 100%;
}

#commandbar-wrapper .rc-header-guidance {
  background: rgba(174, 177, 221, 1) !important;
  filter: brightness(135%);
  border-radius: 2px 2px 0px 0px !important;
  border-bottom: 0px !important;
  padding: 8px 30px 2px 30px !important;
  margin: 0px 10px 0px 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 10px !important;
  font-weight: 400;
  text-align: center;
}

@keyframes fontbulger {
  0% {
    font-size: 10px;
  }
  30% {
    font-size: 15px;
  }
  100% {
    font-size: 12px;
  }
}

@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}

.commandbar-category-collapse {
  transform: scale(1, -1);
  transition: 0.3s ease-in;
  cursor: pointer;
}

.commandbar-category-expand {
  transition: 0.3s ease-in;
  cursor: pointer;
}

/* Nudges - modal */

.commandbar-nudge-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2147483642 !important;
}

.commandbar-nudge-modal-mock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
  transition: margin-right cubic-bezier(0.78, 0.14, 0.15, 0.86) 0.3s;
  z-index: 2147483642 !important;
}

/* Nudges - media */

.commandbar-help-doc-title::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, transparent 66.67%, gray 93.08%);
  content: '';
}
